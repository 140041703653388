var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { JSONStruct, property } from 'badphraim/data/JSONStruct';
export { HTTPPromise, HTTPPromiseAborter } from 'badphraim/ajax/Http';
export class ErrorHTTPDescription extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.http_status = -1;
        /** @override */ this.message = '';
        /** @override */ this.type = '';
    }
}
__decorate([
    property,
    __metadata("design:type", Number)
], ErrorHTTPDescription.prototype, "http_status", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ErrorHTTPDescription.prototype, "message", void 0);
__decorate([
    property,
    __metadata("design:type", String)
], ErrorHTTPDescription.prototype, "type", void 0);
export class ErrorHTTP extends JSONStruct {
    constructor() {
        super(...arguments);
        /** @override */ this.error = new ErrorHTTPDescription();
    }
}
__decorate([
    property,
    __metadata("design:type", Object)
], ErrorHTTP.prototype, "error", void 0);
export class MultiErrorHTTP extends ErrorHTTP {
    constructor() {
        super(...arguments);
        this.errors = [];
    }
}
__decorate([
    property,
    __metadata("design:type", Array)
], MultiErrorHTTP.prototype, "errors", void 0);
// export type Type<T> = Result<T>;
export function isErrorHTTP(result) {
    return result instanceof ErrorHTTP;
}
export function isSuccessHTTP(result) {
    return !isErrorHTTP(result);
}
export class NothingClass {
    /** @override */ arrayOf() {
        throw new Error("Can't wrap to array");
    }
    /** @override */ generate() {
        // void
        throw new Error('Cant generate');
    }
    /** @override */ hashMapOf() {
        throw new Error("Can't wrap to hashmap.");
    }
}
