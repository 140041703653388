var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncContainer } from 'badphraim/core/AsyncContainer';
import { PubSubClient } from 'badphraim/PubSubClient';
import { Ajax } from '../Ajax';
import { GlobalWindowPropertyHelper } from 'badphraim/core/GlobalWindowPropertyHelper';
import { EventDispatcher } from '../EventDispatcher';
import { UrlMangler } from 'badphraim/UrlMangler';
export class CerumConnectBase extends PubSubClient {
    constructor() {
        super(...arguments);
        this._ready = new AsyncContainer();
        this._closeEvents = [];
        this._connected = 0;
    }
    get ready() {
        return this._ready.getValue();
    }
    static _getOrCreateGlobalInstance(createInstance, ident) {
        let registry = {};
        const globalRegistry = GlobalWindowPropertyHelper.get('CerumConnectRegister', registry);
        if (!globalRegistry) {
            throw new Error('Finner ikke globalt registry');
        }
        registry = globalRegistry;
        if (!ident) {
            ident = '';
        }
        if (ident in registry) {
            return registry[ident];
        }
        return (registry[ident] = createInstance(ident));
    }
    /** @override */ handleEvent(event) {
        // void
        console.error('CerumConnect.handleEvent(..)', event);
        EventDispatcher.getGlobalInstance().triggerEvent(event.event, null, null, null, event);
    }
    /** @override */ publish(channel, data) {
        const _super = Object.create(null, {
            publish: { get: () => super.publish }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            return _super.publish.call(this, channel, data);
        });
    }
    /** @override */ subscribe(channel, callback, timeoutSec) {
        const _super = Object.create(null, {
            subscribe: { get: () => super.subscribe }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield this.ready;
            return _super.subscribe.call(this, channel, callback, timeoutSec);
        });
    }
    _onclose(_closeEvent) {
        this._ready = new AsyncContainer();
        // Scrub old close-events
        const currentTime = new Date().getTime();
        const threshold = 30; // 30 minutt
        const thresholdMs = threshold * 60 * 1000;
        this._closeEvents = this._closeEvents.filter((occuredAt) => occuredAt >= currentTime - thresholdMs);
        // Log the current event
        this._closeEvents.push(new Date().getTime());
        // check for too much trouble
        if (this._closeEvents.length > 5) {
            // Vi har hatt for mye trøbbel... vi gir opp
            console.error('Abandoning websocket reconnection procedure. Too many reconnects in less than ' +
                threshold +
                ' minutes.');
            this._ready.reject('too many reconnects at ' + new Date().toString());
            return;
        }
        const secondToLast = this._closeEvents.slice(-2, -1).shift();
        if (secondToLast !== undefined) {
            const elapsed = currentTime - secondToLast;
            if (elapsed < 1 * 60 * 1000) {
                // if this is the second reconnect in less than a minute, hold off for a bit
                console.log('Reconnecting websocket in 5 minutes');
                window.setTimeout(() => {
                    this.startWebSocketClient();
                }, 5 * 60 * 1000);
                return;
            }
        }
        console.log('Reconnecting websocket');
        // Otherwise, just reconnect
        window.setTimeout(() => {
            this.startWebSocketClient();
        }, 5);
        // void
    }
    /** @override */ _onopen() {
        return __awaiter(this, void 0, void 0, function* () {
            let url = yield this.getPubSubTokenUrl();
            if (!url) {
                return;
            }
            if (!this.webSocket) {
                throw new Error('WebSocket er ikke klar');
            }
            url += '?_t=' + new Date().getTime();
            const rawBaseUrl = yield this.getBaseUrl();
            const urlMangler = new UrlMangler(rawBaseUrl);
            const baseUrl = urlMangler.getPath();
            const baseHostname = urlMangler.getHost();
            const server = baseHostname ? baseHostname : document.location ? document.location.hostname : '';
            const token = yield Ajax.stdInstance().doAjax(url);
            const response = yield this.operation('auth', {
                token,
                server,
                base_url: baseUrl,
                client: {
                    client_type: 'cerum-connect',
                    client_version: yield this.getVersionString(),
                    client_agent: navigator.userAgent,
                },
            }, 10);
            if (response.operation_result !== 200) {
                throw new Error('Noe er galt på auth');
            }
            if (this._connected) {
                this._onreconnected();
            }
            this._connected++;
            this._ready.setValue(this);
        });
    }
    _onreconnected() {
        return __awaiter(this, void 0, void 0, function* () {
            // void
            const subscriptions = this.getRegisteredSubscriptions();
            for (const i in subscriptions) {
                const subscription = subscriptions[i];
                this.registerSubscription(subscription.channel, subscription.callback);
            }
        });
    }
}
