var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Ajax as BPAjax } from 'badphraim/ajax/Ajax';
import { HTTPError } from 'badphraim/ajax/Ajax';
import { ErrorHTTP, HTTPPromise, HTTPPromiseAborter, isSuccessHTTP, MultiErrorHTTP, NothingClass, } from './APIBase';
import { NativeType } from './NativeType';
export class APIRestClientBase {
    // eslint-disable-next-line id-length
    static all(argv) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield Promise.all(argv);
            const ok = [];
            const errors = [];
            for (const e of res) {
                if (isSuccessHTTP(e)) {
                    ok.push(e);
                }
                else {
                    errors.push(e);
                }
            }
            if (errors.length) {
                const error = new MultiErrorHTTP();
                error.error.message = 'Multiple errors';
                error.errors = errors;
                return error;
            }
            return ok;
        });
    }
    static getAjax(_scopes) {
        return BPAjax.stdInstance();
    }
    static getRestBaseUrl() {
        throw new Error('You have to implement static async getRestBaseUrl(): Promise<string>');
    }
    static resolve(result) {
        if (isSuccessHTTP(result)) {
            return result;
        }
        throw result;
    }
    static _formData(data) {
        if (data instanceof Blob) {
            return data;
        }
        if (data instanceof FormData) {
            return data;
        }
        const form = new FormData();
        for (const key in data) {
            const anyFieldValue = data[key];
            let fieldValue;
            switch (typeof anyFieldValue) {
                case 'function':
                    continue;
                case 'string':
                    fieldValue = anyFieldValue;
                    break;
                case 'number':
                    fieldValue = String(anyFieldValue);
                    break;
                case 'object':
                    if (anyFieldValue instanceof Blob) {
                        fieldValue = anyFieldValue;
                        break;
                    }
                    if (anyFieldValue instanceof File) {
                        fieldValue = anyFieldValue;
                        break;
                    }
                    fieldValue = JSON.stringify(anyFieldValue);
                    break;
                default:
                    continue;
            }
            form.append(key, fieldValue);
        }
        return form;
    }
    static _delete(formatted_path, path_params, query_params, input_data, generator, scopes, responseType, init) {
        const aborter = new HTTPPromiseAborter();
        return HTTPPromise.newWithFallback((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            aborter.rejector = reject;
            try {
                const response = yield this._fetch('DELETE', formatted_path, path_params, query_params, input_data, aborter.controller.signal, scopes, responseType == 'blob' ? 'blob' : undefined, aborter, init);
                const data = yield this._response(response, responseType);
                resolve(generator.generate(data));
            }
            catch (e) {
                if (e instanceof HTTPError) {
                    if (e.response) {
                        const err = new ErrorHTTP().init(yield e.response.json());
                        resolve(err);
                        return;
                    }
                }
                reject(e);
            }
        }), aborter);
    }
    static _get(formatted_path, path_params, query_params, input_data, generateable, scopes, responseType, init) {
        const aborter = new HTTPPromiseAborter();
        return HTTPPromise.newWithFallback((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            aborter.rejector = reject;
            // void
            try {
                const response = yield this._fetch('GET', formatted_path, path_params, query_params, input_data, aborter.controller.signal, scopes, responseType == 'blob' ? 'blob' : undefined, aborter, init);
                const data = yield this._response(response, responseType);
                resolve(generateable.generate(data));
                return;
            }
            catch (e) {
                if (e instanceof HTTPError) {
                    if (e.response) {
                        const err = new ErrorHTTP().init(yield e.response.json());
                        resolve(err);
                        return;
                    }
                }
                reject(e);
            }
        }), aborter);
    }
    static _response(response, responseType) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (responseType) {
                case 'json':
                    return response.json();
                case 'blob':
                    return response.blob();
                default:
                    // Dette er neppe rett, men gir minst skade
                    console.log(`Mangler/ukjent response type ${responseType} i _fetch, håndterer som json`);
                    return response.json();
            }
        });
    }
    static _post(formatted_path, path_params, query_params, input_data, generator, scopes, responseType, init) {
        const aborter = new HTTPPromiseAborter();
        return HTTPPromise.newWithFallback((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            aborter.rejector = reject;
            try {
                const response = yield this._fetch('POST', formatted_path, path_params, query_params, input_data, aborter.controller.signal, scopes, responseType == 'blob' ? 'blob' : undefined, aborter, init);
                const data = yield this._response(response, responseType);
                resolve(generator.generate(data));
            }
            catch (e) {
                if (e instanceof HTTPError) {
                    if (e.response) {
                        const err = new ErrorHTTP().init(yield e.response.json());
                        resolve(err);
                        return;
                    }
                }
                reject(e);
            }
        }), aborter);
    }
    static _put(formatted_path, path_params, query_params, input_data, generator, scopes, responseType, init) {
        const aborter = new HTTPPromiseAborter();
        return HTTPPromise.newWithFallback((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            aborter.rejector = reject;
            try {
                const response = yield this._fetch('PUT', formatted_path, path_params, query_params, input_data, aborter.controller.signal, scopes, responseType == 'blob' ? 'blob' : undefined, aborter, init);
                const data = yield this._response(response, responseType);
                resolve(generator.generate(data));
            }
            catch (e) {
                if (e instanceof HTTPError) {
                    if (e.response) {
                        const err = new ErrorHTTP().init(yield e.response.json());
                        resolve(err);
                        return;
                    }
                }
                reject(e);
            }
        }), aborter);
    }
    static _patch(formatted_path, path_params, query_params, input_data, generator, scopes, responseType, init) {
        const aborter = new HTTPPromiseAborter();
        return HTTPPromise.newWithFallback((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            aborter.rejector = reject;
            try {
                const response = yield this._fetch('PATCH', formatted_path, path_params, query_params, input_data, aborter.controller.signal, scopes, responseType == 'blob' ? 'blob' : undefined, aborter, init);
                const data = yield this._response(response, responseType);
                resolve(generator.generate(data));
            }
            catch (e) {
                if (e instanceof HTTPError) {
                    if (e.response) {
                        const err = new ErrorHTTP().init(yield e.response.json());
                        resolve(err);
                        return;
                    }
                }
                reject(e);
            }
        }), aborter);
    }
    static _createUrl(formatted_path, path_params, query_params) {
        return __awaiter(this, void 0, void 0, function* () {
            let base;
            if (formatted_path.match(/^https?:\/\//)) {
                const base_url = new URL(formatted_path);
                base = base_url.protocol + '//' + base_url.host;
                formatted_path = decodeURI(base_url.pathname) + base_url.search + base_url.hash;
            }
            else {
                base = yield this.getRestBaseUrl();
            }
            const in_parts = formatted_path.split('/');
            const url_parts = [];
            const path_param_keys = Object.assign({}, path_params);
            for (let i = 0; i < in_parts.length; i++) {
                const part = in_parts[i];
                const m = part.match(/^{(.+)}$/);
                if (m && m[1]) {
                    const param = m[1];
                    if (param in path_params) {
                        url_parts.push(String(path_params[param]));
                        if (param in path_param_keys) {
                            delete path_param_keys[param];
                        }
                    }
                    else {
                        throw new Error('Unknown or missing input ' + param);
                    }
                }
                else {
                    url_parts.push(part);
                }
            }
            if (Object.values(path_param_keys).length > 0) {
                console.error('path_param ble til overs: ', [formatted_path, path_param_keys]);
            }
            let path = url_parts.join('/');
            if (!path.startsWith('/')) {
                path = '/' + path;
            }
            let url = base + path;
            let cnt = 0;
            if (query_params) {
                for (const i in query_params) {
                    url += cnt++ ? '&' : '?';
                    let param = query_params[i];
                    if (param instanceof Array) {
                        param = param.join(',');
                    }
                    url += encodeURIComponent(i) + '=' + encodeURIComponent(param);
                }
            }
            return url;
        });
    }
    static _fetch(method, formatted_path, path_params, query_params, input_data, signal, scopes, responseType, aborter, init) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = yield this._createUrl(formatted_path, path_params, query_params);
            if (aborter) {
                aborter.url = url;
            }
            init = init !== null && init !== void 0 ? init : {};
            init.method = method;
            if (signal && 'AbortSignal' in window) {
                init.signal = signal;
            }
            if (input_data != null) {
                init.body = input_data;
            }
            return this.getAjax(scopes).fetch(url, init, scopes, responseType, aborter);
        });
    }
}
APIRestClientBase.Native = new NativeType();
APIRestClientBase.Nothing = new NothingClass();
