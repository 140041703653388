var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { css, html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { SnackBar } from 'common-uicomponents/components/SnackBar';
import { ArticlesAPI } from 'cerumx-api/cerum/desktop/articles/ArticlesAPI';
import { isErrorHTTP, isSuccessHTTP } from 'cerumx-api/APIRestClient';
import { SelectedItem } from './SelectedItem';
import { Item } from '@vaadin/item';
import { Select } from '@vaadin/select';
import { ListBox } from '@vaadin/list-box';
import { ComboBox } from '@vaadin/combo-box';
import { CerumFormLabel } from 'common-uicomponents/CerumFormLabel';
import { comboBoxRenderer } from '@vaadin/combo-box/lit.js';
let CerumArticleLookup = class CerumArticleLookup extends LitElement {
    static get is() {
        return 'cerum-article-lookup';
    }
    static get styles() {
        return css `
            vaadin-custom-field,
            vaadin-combo-box {
                width: 100%;
            }

            .disabled {
                opacity: 0.4;
            }
        `;
    }
    constructor() {
        super();
        this.articles = [];
        this.disabled = false;
        this.label = '';
        this.errorMessage = '';
        this.required = false;
        this.filteredItems = [];
        this.personPhoneRenderer = (article) => html `
        <div>${article.article_number}/${article.name}</div>
    `;
        this._loadArticles();
    }
    /** @override */ render() {
        return html `
            <cerum-form-label>${this.label}</cerum-form-label>

            ${this.selectedArticle
            ? this._renderSelectedItem()
            : html `<vaadin-combo-box
                      class="validate"
                      .required="${this.required}"
                      .disabled="${this.disabled}"
                      .items="${this.articles}"
                      error-message="${this.errorMessage}"
                      item-label-path="name"
                      item-value-path="id"
                      .filteredItems="${this.filteredItems.length ? this.filteredItems : undefined}"
                      @filter-changed="${this.filterChanged}"
                      @value-changed="${(event) => this._selectedArticleChanged(event)}"
                      ${comboBoxRenderer(this.personPhoneRenderer, [])}
                  ></vaadin-combo-box>`}
        `;
    }
    filterChanged(event) {
        const filter = event.detail.value;
        this.filteredItems = this.articles.filter(({ name, article_number: articleNumber }) => articleNumber.toLowerCase().startsWith(filter.toLowerCase()) ||
            name.toLowerCase().startsWith(filter.toLowerCase()));
    }
    _renderSelectedItem() {
        var _a, _b;
        return html ` <selected-item
            @deselect="${(event) => (this.selectedArticle = event.detail.value)}"
            .disabled="${this.disabled}"
        >
            ${(_a = this.selectedArticle) === null || _a === void 0 ? void 0 : _a.article_number}/${(_b = this.selectedArticle) === null || _b === void 0 ? void 0 : _b.name}
        </selected-item>`;
    }
    _selectedArticleChanged(event) {
        if (!event.detail.value) {
            return;
        }
        const selectedId = parseInt(event.detail.value);
        const selectedArticle = this.articles.find((article) => article.id === selectedId);
        if (selectedArticle) {
            this.selectedArticle = selectedArticle;
        }
    }
    _selectedArticleObserver() {
        this.dispatchEvent(new CustomEvent('selected-article-changed', {
            bubbles: true,
            composed: true,
            detail: {
                value: this.selectedArticle,
            },
        }));
    }
    _loadArticles() {
        return __awaiter(this, void 0, void 0, function* () {
            const articlesResult = yield ArticlesAPI.getV1ArticlesResources({ active: true });
            if (isSuccessHTTP(articlesResult)) {
                this.articles = articlesResult;
            }
            else if (isErrorHTTP(articlesResult)) {
                SnackBar.pushError(articlesResult.error);
            }
        });
    }
    validateArticle() {
        var _a;
        const validateArticleField = (_a = this.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector('.validate');
        if (validateArticleField) {
            if (this.selectedArticle) {
                validateArticleField.removeAttribute('invalid');
            }
            else {
                validateArticleField.setAttribute('invalid', 'true');
            }
        }
        return Boolean(this.selectedArticle);
    }
    /** @override */
    updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'selectedArticle':
                    this._selectedArticleObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
};
CerumArticleLookup._deps = [SelectedItem, Select, Item, ListBox, ComboBox, CerumFormLabel];
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "articles", void 0);
__decorate([
    property({ type: Object }),
    __metadata("design:type", Object)
], CerumArticleLookup.prototype, "selectedArticle", void 0);
__decorate([
    property({ type: Boolean, attribute: 'disabled' }),
    __metadata("design:type", Boolean)
], CerumArticleLookup.prototype, "disabled", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumArticleLookup.prototype, "label", void 0);
__decorate([
    property({ type: String }),
    __metadata("design:type", String)
], CerumArticleLookup.prototype, "errorMessage", void 0);
__decorate([
    property({ type: Boolean }),
    __metadata("design:type", Boolean)
], CerumArticleLookup.prototype, "required", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], CerumArticleLookup.prototype, "filteredItems", void 0);
CerumArticleLookup = __decorate([
    customElement(CerumArticleLookup.is),
    __metadata("design:paramtypes", [])
], CerumArticleLookup);
export { CerumArticleLookup };
